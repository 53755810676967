import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { Image } from "./image";

export const ImageGallery = ({ images }: { images: any[] }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const slidesContent = images.map((image) => (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Image
        className="h-full w-full"
        imageId={image.asset._id || image.asset._ref}
        imgClassName="h-full max-w-full"
        imgStyle={{ objectFit: "contain" }}
      />
    </div>
  ));

  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex flex-wrap justify-center w-full">
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() =>
                setLightboxController({
                  toggler: !lightboxController.toggler,
                  slide: index + 1,
                })
              }
              className="cursor-pointer w-1/2 sm:w-64 p-3"
            >
              <Image
                imageId={image.asset._id || image.asset._ref}
                aspectRatio={1}
              />
            </div>
          ))}
        </div>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slidesContent}
        slide={lightboxController.slide}
      />
    </>
  );
};
